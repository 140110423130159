<template>
  <div class="onlyCourseGoodsBg">
    <div class="courseGoodsCont" ref="courseGoodsContRef">
        <div class="content">
            <div class="goodsSearchCont"  ref="searchBtnRef">
                <div class="searchIptBox fr">
                <input type="text" ref="searchInp" class="searchIpt" v-model="goodsKey" v-on:keyup.enter="getData('searchBtn')" placeholder="输入课程名称"/>
                <span class="searchBtn"  @click="getData('searchBtn')">
                  <svg-icon icon-class="search"></svg-icon>
                </span>
                </div>
            </div>
            <div class="classifyArea">
                <div class="courseClassifyItem">
                    <div class="courseClassifyTitle">课程分类:</div>
                    <div class="courseClassifyItemListCont">
                        <ul class="courseClassifyItemList firstCourseClassifyItemList" >
                        <li v-for="(item, idx) in  firstCourseClassify" :key="idx">
                            <span :class="{active: item.isActive }"  @click="clickFirstClassifyHandle(idx, item)">{{item.name}}</span>
                        </li>
                        </ul>
                        <ul class="courseClassifyItemList marginTop16"   :class="{borderTopStyle: secondCourseClassify && secondCourseClassify}" v-if="secondCourseClassify.length">
                        <li v-for="(item, idx) in  secondCourseClassify" :key="idx">
                            <span :class="{active: item.isActive }"  @click="clickSecondClassifyHandle(idx, item)">{{item.name}}</span>
                        </li>
                        </ul>
                        <ul class="courseClassifyItemList marginTop16 threeClassifyStyle"  v-if="threeCourseClassify.length">
                        <li v-for="(item, idx) in  threeCourseClassify" :key="idx">
                            <span :class="{active: item.isActive }"  @click="clickThreeClassifyHandle(idx, item)">{{item.name}}</span>
                        </li>
                        </ul>   
                    </div>
                </div> 
                <div class="comprehensiveSortItem">
                    <div class="comprehensiveSortItemTitle">综合排序:</div>
                    <div class="comprehensiveSortItemListCont">
                        <ul class="comprehensiveSortItemList">
                        <li  v-for="(item, idx) in  typeList" :key="idx">
                            <span  :class="{active: item.isActive }" @click="clickTypeSortHandle(idx, item)">{{item.name}}</span>
                        </li>
                        </ul>
                    </div>    
                </div>   
            </div>
            <div class="courseGoodsListCont">
                <div class="courseGoodsList couseGoodsListStyle"   v-if="courseListArray.length && !Array.isArray(courseListArray[0])">
                    <div class="goodCourse-list">
                        <div class="course-item" v-for="item in courseListArray" :key="item.id" @click="jumpGoodsDetailHandle(item)">
                        <img :src="item.pic?item.pic:''"/>
                        <div class="text-content">
                            <h3 :title="item.name">{{item.name}}</h3>
                            <div class="tag-list-wraper">
                              <ul class="tag-list">
                                  <li class="tag-item tag-active  hide"
                                    v-for="(tag, index) in item.labelNameList"
                                    :key="index"
                                  >
                                    <el-tooltip :content="tag" placement="top-start">
                                        <div class="text hide"> {{tag}}</div>
                                    </el-tooltip>

                                  </li>

                              </ul>
                            </div>
                            <p>{{item.isFree?'免费':'￥'+item.price}}</p>
                        </div>
                        </div>
                    </div>
                  
                </div>
                
                
        
                <div class="noGoodsTips" v-if="isShowSearchResult && !paging.total">没有搜索到相关课程哟～</div>
                <div class="nothingTips" v-if="!courseListArray.length&&!isShowSearchResult">还没有课程哟～ </div>
                <paging class="paging pagingCustom" :paging="paging" v-if="paging.total" />
                
            </div>
      </div>
    </div>
  </div>
</template>

<script>
import paging from "../../../layout/paging";
import Vue from 'vue'

export default {
  name: 'courseGoods',
  components: {
    paging,

  },
  data () {
    return {
          branchInfo:{
          },
          firstCourseClassify:[],

          secondCourseClassify: [],

          threeCourseClassify: [],

          paging: {
              params: {
                  pageNum: 1,
                  pageSize: 12
              },
              pageSizes: [10,12, 25, 50],
              total: 0,
              totalPages: 0,
              currentSize: 0,
          },
          typeList: [ 
              {
                type: 3,
                name: '智能排序',      
                isActive: true

              },
              {
                type: 1,
                name: '最新课程',      
                isActive: false

              },
              {
                type: 2,
                name: '人气排序',
                isActive: false

              },     
          ],
          currentType: 3, // 默认智能排序
          categoryCode: this.$route.query.categoryCode?this.$route.query.categoryCode:'',
          categoryId:this.$route.query.categoryId?this.$route.query.categoryId:'',
          firstCategoryCode: '',
          secondCategoryCode: '',
          threeCategoryCode: '',
          goodsKey: '',
          isShowSearchResult: false,
          courseListArray:[
          ],
          isLogin: localStorage.getItem('token'),
    }
  },
  watch:{
    'goodsKey'(newVal){
        if(!newVal){
          this.paging.params.pageNum = 1
          this.getData()
        }
    },
  },
  filters: {
  },
  created () {
    this.getbranchId();
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    //获取当前域名对应branchId
    async getbranchId(){
      await this.$api.salesSystem.getConfiguration().then((res) => {
				if (res.data) {
					this.branchInfo = res.data;
          console.log('branch==============',this.branchInfo)
          this.getClassifyFindAll();   
				}
			})
    },
    //获取商品分类
    async getClassifyFindAll(){
        const params = {}
        params.businessType = 2
        await this.$api.salesSystem.findAll(params)
          .then(res=>{
              let allName = {
                'code': '',          
                'name': "全部",
                'showTag': true,
              }
              if (res.data && res.data.length) {
                this.firstCourseClassify = res.data;
                this.firstCourseClassify.unshift(allName);
                if (this.$route.query.categoryCode) {
                  let currentaCeIdx = 0;
                        let currentaCeIdx2 = 0;
                        let isTwoCe = false;
                        let currentaCeIdx3 = 0;
                        let isThreeCe = false;
                        this.firstCourseClassify.forEach((val, idx) => {
                            if (this.$route.query.categoryCode.indexOf(val.code)!=-1) {
                                console.log(val, idx)
                                currentaCeIdx = idx
                                this.secondCourseClassify = val.children || []
                                if(this.secondCourseClassify.length>0){
                                    this.secondCourseClassify.forEach((val2, idx2) => {
                                        if(this.$route.query.categoryCode.indexOf(val2.code)!=-1){
                                            isTwoCe = true;
                                            console.log(val2, idx2)
                                            currentaCeIdx2 = idx2
                                            this.threeCourseClassify = val2.children || [];
                                            if(this.threeCourseClassify.length>0){
                                                this.threeCourseClassify.forEach((val3, idx3) => {
                                                    if(this.$route.query.categoryCode == val3.code){
                                                        isThreeCe = true;
                                                        console.log(val3, idx3)
                                                        currentaCeIdx3 = idx3
                                                    }
                                                })
                                            }
                                        }
                                    })
                                }
                            }
                        })
                        this.currentTabIdx  = 0       
                        if(!isTwoCe&&!isThreeCe){             
                            this.categoryCode = this.firstCourseClassify[currentaCeIdx].code;
                            this.categoryId = this.firstCourseClassify[currentaCeIdx].id;
                            this.$set(this.firstCourseClassify[currentaCeIdx], 'isActive', true) 
                        }else{
                            if(isTwoCe&&!isThreeCe){
                                this.categoryCode = this.secondCourseClassify[currentaCeIdx2].code;
                                this.categoryId = this.secondCourseClassify[currentaCeIdx2].id;
                                this.$set(this.firstCourseClassify[currentaCeIdx], 'isActive', true)
                                this.$set(this.secondCourseClassify[currentaCeIdx2], 'isActive', true)       
                                
                            }else if(isTwoCe&&isThreeCe){
                                this.categoryCode = this.threeCourseClassify[currentaCeIdx3].code;
                                this.categoryId = this.threeCourseClassify[currentaCeIdx3].id;
                                this.$set(this.firstCourseClassify[currentaCeIdx], 'isActive', true)
                                this.$set(this.secondCourseClassify[currentaCeIdx2], 'isActive', true)
                                this.$set(this.threeCourseClassify[currentaCeIdx3], 'isActive', true)
                            }
                        }
                } else {
                  Vue.set(this.firstCourseClassify[0], 'isActive', true)
                  this.categoryCode = this.firstCourseClassify[0].code;
                  this.categoryId = this.firstCourseClassify[0].id; 
                }
              }
              this.getData();
          }).catch(() => {
            this.getData();
          })
    },
    //点击一级分类
    async clickFirstClassifyHandle(idx, item) {    
      this.paging.params.pageNum = 1
      this.secondCourseClassify = []
      this.threeCourseClassify = []
      this.firstCategoryCode = item.code;
      this.categoryCode = this.firstCategoryCode;
      this.categoryId = item.id;
      await this.getData()
      this.firstCourseClassify.forEach((val, index) => {
        if (idx == index) {
          Vue.set(val, 'isActive', true);
          if (val.children) {
              if (val.children.length == 1) {
                  this.secondCourseClassify = []
              } else {
                  this.secondCourseClassify = val.children
                  this.secondCourseClassify.forEach((val) => {
                  // if (index == 0) {
                  //    Vue.set(val, 'isActive', true);
                  //  } else {
                          Vue.set(val, 'isActive', false)
                  //   }
                  })                
              }
          } else {
              this.secondCourseClassify = []
              this.threeCourseClassify = []
          }
        } else {
          Vue.set(val, 'isActive', false)
        }
      })
    },
    //点击二级分类
    async clickSecondClassifyHandle(idx, item) {
        this.paging.params.pageNum = 1
        this.threeCourseClassify = []
        this.secondCategoryCode = item.code
        this.categoryCode =  item.code;
        this.categoryId = item.id;
        await this.getData()
        this.secondCourseClassify.forEach((val, index) => {
          if (idx == index) {
            Vue.set(val, 'isActive', true);
            if (val.children) {
                this.threeCourseClassify = val.children
                this.threeCourseClassify.forEach((val) => {
                Vue.set(val, 'isActive', false)
                })
            } else {
              this.threeCourseClassify = []
            }
          } else {
            Vue.set(val, 'isActive', false)
          }
      })
    },
    //点击三级分类
    async clickThreeClassifyHandle(idx, item) {
        this.paging.params.pageNum = 1
        this.threeCategoryCode = item.code
        this.categoryCode = item.code;
        this.categoryId = item.id;
        await this.getData()
        this.threeCourseClassify.forEach((val, index) => {
          if (idx == index) {
            Vue.set(val, 'isActive', true);

          } else {
            Vue.set(val, 'isActive', false)
          }
      })
    },
    //排序
    async clickTypeSortHandle(idx, item) {
        this.paging.params.pageNum = 1
        this.typeList.forEach((val, index) => {
          if (idx == index) {
            Vue.set(val, 'isActive', true);
            this.currentType = item.type;
            this.getData()
          } else {
            Vue.set(val, 'isActive', false)
          }
        })
    },
    //获取商品列表
    getData(parse) {
      return new Promise((resolve, reject)=>{
          if (parse == 'searchBtn' && this.$refs.searchBtnRef && !(this.goodsKey.trim()) ) {
              this.$message.error('请输入关键字');
              return false
          }
          if (parse == 'searchBtn') {
            this.paging.params.pageNum = 1
            this.isShowSearchResult = true
          } else {
            this.isShowSearchResult = false
          }
              this.$api.salesSystem.findGoodsList(
              {
                  pageNum: this.paging.params.pageNum,
                  pageSize:  this.paging.params.pageSize,
              },{
                  branchId: this.branchInfo.branchId!=-1&&this.branchInfo.branchId!=null?this.branchInfo.branchId:null,
                  categoryCode: this.categoryCode?this.categoryCode:null,
                  categoryId: this.categoryId?this.categoryId:null,
                  name: this.goodsKey?this.goodsKey:null,
                  type: this.currentType,
              }
            )
            .then(res=>{
                if (res.data && res.data.list) {
                  this.courseListArray = res.data.list;
                  this.paging.total = res.data.total; 
                  this.paging.totalPages = res.data.pages
                  this.paging.currentSize = res.data.size
                  resolve(res)
                } else {
                    reject(res.message)
                }
            }).catch(err=>{
                reject(err)
                console.log('err', err)

            })
      })   
    },
    //跳转商品详情
    jumpGoodsDetailHandle(item) {
        this.$router.push({
          path: '/courseGoodsDetail',
          query: {
            goodsId:item.goodsId,
            goodsKind:item.goodsKind,
            isNeedBreadcrumb:1
          }
        }) 
    },
  },
};
</script>

<style lang="stylus" scoped>
 @import "../css/courseGoods.styl"
</style>
